import { Link, useForm, usePage } from '@inertiajs/react';
import { Alert, Button, Input } from '../../../shared/components';
import { LoginLayout } from '../../layouts';

const LoginView = () => {
    const { flash } = usePage().props;

    const { data, setData, post, processing, errors } = useForm({
        email: '',
        password: '',
    });

    const handleFormSubmit = e => {
        e.preventDefault();

        post('/login');
    };

    return (
        <form onSubmit={handleFormSubmit} noValidate>
            <p className='mt-4 mb-11 text-lg'>Log in op jouw account</p>

            {flash?.status && <Alert message={flash.status} />}

            {flash?.message && (
                <Alert message={flash.message} variant='error' />
            )}

            <Input
                autoFocus={true}
                autoComplete='email'
                placeholder='E-mailadres'
                type='email'
                value={data.email || ''}
                onChange={e => setData('email', e.target.value)}
                error={errors?.email}
            />
            <Input
                autoComplete='current-password'
                placeholder='Wachtwoord'
                type='password'
                value={data.password || ''}
                onChange={e => setData('password', e.target.value)}
                error={errors?.password}
            />
            <div className='mb-5 flex justify-end'>
                <Link
                    href='/forgot-password'
                    className='text-primary-500 hover:text-primary-200 transition-colors ease-in-out'
                >
                    Wachtwoord vergeten
                </Link>
            </div>
            <Button
                content='Inloggen'
                type='submit'
                disabled={processing}
                loading={processing}
            />
        </form>
    );
};

LoginView.layout = page => <LoginLayout>{page}</LoginLayout>;

export default LoginView;
